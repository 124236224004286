import React from "react";
import Script from "next/script";
import Head from "next/head";

export default function TpWidget() {
  return (
    <>
      <Head>
        <link
          rel="preload"
          href="https://assets.setmore.com/website/v2/images/common-images/trustpilot-widget.svg"
          as="image"
        />
        <link
          rel="preload"
          as="script"
          href="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
        />
      </Head>
      <Script
        id="tpWidget"
        strategy="lazyOnload"
        src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
      />
    </>
  );
}
